<template>
  <div class="x-wrap">
    <div class="x-page-title">
      <h3>空投管理</h3>
    </div>

    <div class="x-main">
      <!-- 筛选条件 -->
      <div class="x-q-panel">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="x-con-item">
              <label>空投id：</label>
              <el-input
                placeholder="请输入空投id"
                v-model="query.id"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="x-con-item">
              <label>发布日期：</label>
              <el-date-picker
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="query.dateRange"
              ></el-date-picker>
            </div>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              class="x-q-btn"
              @click="
                query.pageIndex = 1;
                doQuery();
              "
              >筛选</el-button
            >
            <el-button type="text" @click="doQueryReset">重置</el-button>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8"
            ><div class="x-con-item mt25">
              <label>空投类型：</label>
              <el-select
                placeholder="请选择空投类型"
                v-model="query.airdropType"
              >
                <el-option label="藏品空投" value="1"></el-option>
                <el-option label="盲盒" value="2"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="12"
            ><div class="x-con-item mt25">
              <label>藏品/盲盒名称：</label>
              <el-input
                placeholder="请输入藏品/盲盒名称"
                v-model="query.airdropName"
              ></el-input>
            </div>
          </el-col>
        </el-row>
      </div>

      <!-- 筛选结果 -->
      <div class="x-table-container">
        <div class="x-table-caption">
          <el-button
            icon="el-icon-refresh"
            type="primary"
            plain
            size="small"
            @click="doRefresh"
            >刷新</el-button
          >
          <el-button
            type="primary"
            size="small"
            class="fr"
            @click="handleCreate"
            >发放新空投</el-button
          >
        </div>

        <div
          class="x-table"
          v-loading="showLoading"
          element-loading-text="数据刷新中..."
        >
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            @selection-change="setSelection"
          >
            <!-- <el-table-column
              type="selection"
              align="center"
              width="80"
            ></el-table-column> -->
            <el-table-column
              prop="id"
              label="空投序号"
              width="200"
            ></el-table-column>
            <el-table-column prop="airdropType" label="空投类型">
              <template slot-scope="scope">
                <span class="font-main" v-if="scope.row.airdropType == 1"
                  >藏品空投</span
                >
                <span
                  class="font-success"
                  v-else-if="scope.row.airdropType == 2"
                  >盲盒空投</span
                >
              </template></el-table-column
            >
            <el-table-column
              prop="airdropName"
              label="空投/盲盒名称"
            ></el-table-column>
            <el-table-column
              prop="totalCount"
              label="空投数量"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="succCount"
              label="成功数量"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="createdAt"
              label="空投时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="updatedAt"
              label="完成时间"
              align="center"
            ></el-table-column>
            <el-table-column prop="status" label="空投状态" align="center">
              <template slot-scope="scope">
                <span class="font-primary" v-if="scope.row.status == 1"
                  ><span class="el-icon-loading"></span>正在空投</span
                >
                <span class="font-success" v-else-if="scope.row.status == 2"
                  ><span class="el-icon-check"></span>空投完成</span
                >
              </template></el-table-column
            >
            <el-table-column label="操作" align="center" width="280">
              <template slot-scope="scope">
                <div class="flex-box-around">
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="goDownload(scope.row.excelUrl)"
                    >下载空投名单</el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="x-pager right">
            <el-pagination
              background
              layout="sizes, prev, pager, next"
              :current-page.sync="query.pageIndex"
              :page-size="query.pageSize"
              :page-sizes="[10, 20, 30, 50]"
              :total="total"
              @size-change="setPageSize"
              @current-change="setPageIndex"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AirdropQuery } from "@/api/module/airdrop";

export default {
  name: "AirdropIndex",
  data() {
    return {
      showLoading: false,
      query: {
        id: "",
        dateRange: [],
        airdropType: "",
        airdropName: "",
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
    };
  },

  created() {
    this.doQuery();
  },
  methods: {
    handleCreate() {
      this.$router.push("/airdrop/create");
    },
    goDownload(url) {
      window.open(url, "安装包下载");
    },

    // 查询
    doQuery() {
      let query = this.setQueryParams();
      AirdropQuery(query).then((r) => {
        this.total = r.total;
        this.tableData = r.list;
      });
    },

    setQueryParams() {
      let query = `?pagination=true&page=${this.query.pageIndex}&pageSize=${this.query.pageSize}`;
      if (this.query.id) {
        query += `&id=${this.query.id}`;
      }
      if (this.query.dateRange.length) {
        query += `&releaseBegin=${this.query.dateRange[0]}&releaseEnd=${this.query.dateRange[1]}`;
      }
      if (this.query.airdropName) {
        query += `&airdropName=${this.query.airdropName}`;
      }
      if (this.query.airdropType) {
        query += `&airdropType=${this.query.airdropType}`;
      }
      return query;
    },

    doQueryReset() {
      this.query = {
        id: "",
        dateRange: [],
        airdropType: "",
        airdropName: "",
        pageIndex: 1,
        pageSize: 10,
      };
      this.doQuery();
    },

    doRefresh() {
      this.showLoading = true;
      this.doQueryReset();
      setTimeout(() => {
        this.$message({
          type: "success",
          message: "数据更新完毕！",
          duration: 1300,
          onClose: () => {
            this.showLoading = false;
          },
        });
      }, 1350);
    },

    setPageSize(pageSize) {
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.doQuery();
    },

    // 设置页码
    setPageIndex(pageIndex) {
      this.query.pageIndex = pageIndex;
      this.doQuery();
    },
  },
};
</script>